import { Component, OnInit, Input, HostListener, ElementRef } from '@angular/core';
import { ScrollToClass } from './../../../../../common/scrollTo.class';

@Component({
    selector: 'app-payment-logos',
    templateUrl: './payment-logos.component.html',
    styleUrls: ['./payment-logos.component.sass']
})

export class PaymentLogosComponent extends ScrollToClass implements OnInit {
    @Input() class;
    @Input() buttonText;
    @Input() buttonId;
    @Input() title;
    @Input() loadImage;
    @Input() subtext;

    public isTablet: boolean;
    private tld = 'co';

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.isTablet = window.innerWidth <= 992 ? true : false;
    }

    constructor(
        private el: ElementRef
    ) {
        super(el);
        this.title = '¡Obtén tu hosting ya!';
        this.buttonText = 'Ver planes';
    }

    ngOnInit() {
        this.isTablet = window.innerWidth <= 992 ? true : false;
        if (this.tld === 'co') {
            if (this.title === '¡Obtén tu hosting ya!') {
                this.title = '¡Obtenga su hosting ya!';
            } else if (this.title === '¡Conviértete en un Reseller ahora!') {
                this.title = '¡Conviértase en un Reseller ahora!';

            }
        } else if (this.tld === 'ar') {
            if (this.title === '¡Obtén tu hosting ya!') {
                this.title = '¡Obten tu hosting ya!';
            } else if (this.title === '¡Conviértete en un Reseller ahora!') {
                this.title = '¡Convertíte en un Reseller ahora!';
            }
        }
    }

    public navigateToSection(className) {
        setTimeout(() => {
            this.scrollToSection(className);
        }, 200);
    }

    public getImgPayment(platform, zoom): string {
        if (platform === 'desktop') {
            if (this.tld === 'cl' && zoom === '1x') {
                return '/assets/images/shared/payment-methods-desktop@1x.png';
            }
            if (this.tld === 'cl' && zoom === '2x') {
                return '/assets/images/shared/payment-methods-desktop@2x.png 2x';
            }
            if (this.tld === 'pe' && zoom === '1x') {
                return '/assets/images/shared/payment-pe-methods-desktop@1x.png';
            }
            if (this.tld === 'pe' && zoom === '2x') {
                return '/assets/images/shared/payment-pe-methods-desktop@2x.png 2x';
            }
            if (this.tld === 'mx' && zoom === '1x') {
                return '/assets/images/shared/payment-mx-methods-desktop@1x.png';
            }
            if (this.tld === 'mx' && zoom === '2x') {
                return '/assets/images/shared/payment-mx-methods-desktop@2x.png 2x';
            }
            if (this.tld === 'co' && zoom === '1x') {
                return '/assets/images/shared/payment-co-methods-desktop@1x.png';
            }
            if (this.tld === 'co' && zoom === '2x') {
                return '/assets/images/shared/payment-co-methods-desktop@2x.png 2x';
            }
            if (this.tld === 'ar' && zoom === '1x') {
                return '/assets/images/shared/payment-ar-methods-desktop@1x.png';
            }
            if (this.tld === 'ar' && zoom === '2x') {
                return '/assets/images/shared/payment-ar-methods-desktop@2x.png 2x';
            }
        }
        if (platform === 'mobile') {
            if (this.tld === 'cl' && zoom === '1x') {
                return '/assets/images/shared/payment-methods-mobile@1x.png';
            }
            if (this.tld === 'cl' && zoom === '2x') {
                return '/assets/images/shared/payment-methods-mobile@2x.png 2x';
            }
            if (this.tld === 'pe' && zoom === '1x') {
                return '/assets/images/shared/payment-pe-methods-mobile@1x.png';
            }
            if (this.tld === 'pe' && zoom === '2x') {
                return '/assets/images/shared/payment-pe-methods-mobile@2x.png 2x';
            }
            if (this.tld === 'mx' && zoom === '1x') {
                return '/assets/images/shared/payment-mx-methods-mobile@1x.png';
            }
            if (this.tld === 'mx' && zoom === '2x') {
                return '/assets/images/shared/payment-mx-methods-mobile@2x.png 2x';
            }
            if (this.tld === 'co' && zoom === '1x') {
                return '/assets/images/shared/payment-co-methods-mobile@1x.png';
            }
            if (this.tld === 'co' && zoom === '2x') {
                return '/assets/images/shared/payment-co-methods-mobile@2x.png 2x';
            }
            if (this.tld === 'ar' && zoom === '1x') {
                return '/assets/images/shared/payment-ar-methods-mobile@1x.png';
            }
            if (this.tld === 'ar' && zoom === '2x') {
                return '/assets/images/shared/payment-ar-methods-mobile@2x.png 2x';
            }
        }
        return '';
    }

}
