import { Injectable } from '@angular/core';
import { getLocaleCurrencySymbol } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { CONTACTS } from '../contacts.constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { Contact } from '../contact';
import { CONTENT_CL } from '../content/content-cl';
import { CONTENT_PE } from '../content/content-pe';
import { CONTENT_CO } from '../content/content-co';
import { CONTENT_MX } from '../content/content-mx';
import { CONTENT_AR } from '../content/content-ar';


@Injectable()
export class LocaleService {
    contactList: Contact[] = CONTACTS;

    locale: string;
    symbol: string;
    currencyName: string;

    currentContact = this.contactList[0];
    currentCountryCode = this.currentContact.country_code;

    contactSource: BehaviorSubject<Contact>;
    contactMessage: Observable<Contact>;

    TLD = 'co'.toLowerCase(); // window.location.href.split('.')[2].split('/')[0].toLowerCase();

    selectorGTM = {
        GTMcl: 'GTM-WBPLKNX',
        // GTMco: '',
        // GTMpe: ''
    };

    selectorGtag = {
        clGtag: 'UA-86671316-3',
        //coGtag: '',
        //peGtag: ''
    };

    constructor(
        private http: HttpClient,
    ) {
        this.locale = this.getLocale(this.TLD);
        this.symbol = getLocaleCurrencySymbol(this.locale);
        // this.currencyName = getLocaleCurrencyName(this.locale);
        this.currentCountryCode = this.TLD;
        this.currentContact = this.getContact();
        this.contactSource = new BehaviorSubject<Contact>(this.currentContact);
        this.contactMessage = this.contactSource.asObservable();
    }

    updateContact(contact): void {
        this.contactSource.next(contact);
    }

    getMessage(): Observable<Contact> {
        return this.contactMessage;
    }

    getContact(): Contact {
        return this.contactList.filter(c => c.country_code === this.currentCountryCode)[0];
    }

    getLocale(tld: string): string {
        const TLD = tld.toLowerCase();
        if (TLD === 'net') {
            return 'en-US';
        } else if (TLD === 'cl' || TLD === 'ar' || TLD === 'mx' ||
            TLD === 'ec' || TLD === 'co' || TLD === 'pe') {
            return 'es-' + (TLD.toUpperCase());
        } else {
            return 'es-CL';
        }
    }
    getContent() {
        switch (this.locale) {
            case 'es-PE':
                return CONTENT_PE;
            case 'es-CO':
                return CONTENT_CO;
            case 'es-MX':
                return CONTENT_MX;
            case 'es-AR':
                return CONTENT_AR;
            default:
                return CONTENT_CL;
        }
    }

    getGTM(): string {
        let element: string;
        switch (this.locale) {
            // case 'es-CO':
            //     element = this.selectorGTM.GTMco;
            //     break;
            // case 'es-PE':
            //     element = this.selectorGTM.GTMpe;
            //     break;
            // case 'es-MX':
            //     break;
            // case 'es-AR':
            //     break;
            case 'es-CL':
                element = this.selectorGTM.GTMcl;
                break;
            default:
                element = '';
        }
        return element;
    }
    //Selection Google Analytics ID
    getGtag(): string {
        let element: string;
        switch (this.locale) {
            // case 'es-CO':
            //     element = this.selectorGtag.coGtag;
            //     break;
            // case 'es-PE':
            //     element = this.selectorGtag.peGtag;
            //     break;
            // case 'es-MX':
            //     break;
            // case 'es-AR':
            //     break;
            case 'es-CL':
                element = this.selectorGtag.clGtag;
                break;
            default:
                element = '';
        }
        return element;
    }
}
